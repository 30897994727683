@use "@els/els-styleguide-core/scss/core" as *;

$eols-reorder-item-padding: $els-space-3o4;
$eols-reorder-item-border-width: $els-space-1o8;
$eols-reorder-item-width: 32 * $els-space;

.c-ssa-reorder {
  padding: $els-space-1o2;
  margin-top: $els-space;

  &__row {
    margin: $els-space 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item {
    border: $eols-reorder-item-border-width solid $els-color-n5;
    width: $eols-reorder-item-width;
    cursor: grab;
    position: relative;
    padding: $eols-reorder-item-padding;

    @include mq($until: mobile) {
      width: 100%;
    }

    &--active {
      border: $eols-reorder-item-border-width solid $els-color-primary;
      background: $els-color-n1;
    }
  }

  &__context {
    margin-top: $eols-reorder-item-padding + $eols-reorder-item-border-width;
  }

  &__number {
    width: $els-space;
  }
}

.c-ssa-answer-icon {
  visibility: hidden;
  width: $els-space-1x1o2;
  height: $els-space-1x1o2;
  border-radius: 50%;
  color: $els-color-background;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &--correct {
    background-color: $els-color-confirm;
  }

  &--incorrect {
    background-color: $els-color-warn;
  }

  &--visible {
    visibility: visible
  }
}
