@use "@els/els-styleguide-core/scss/core" as *;

.c-ssa-eaq-student-performance-details {
  &__description-block {
    background-color: $els-color-n0;
    padding: $els-space $els-space-2x;
  }
  &__gauge-chart {
    text-align: center;
    background-color: $els-color-background;
    width: auto;
  }
  &__page-content {
    padding: $els-space-2x;
  }
  &__question-item {
    border: $els-thick-keyline-width solid $els-color-n2;
  }
  .gauge-chart__info {
    padding: 0 $els-space $els-space;
  }
  .gauge-chart__segment {
    padding-top: $els-space;
  }
  .gauge-chart__circle {
    margin-left: $els-space-1o2;
  }
}
